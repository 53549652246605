import React from 'react'

export const DeepCleaningIcon = ({ className }) => (
  <svg className="h-12 w-12 mr-4 mt-2 dark:fill-white fill-malibu-500 flex-shrink-0" width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
    <path d="M55 51H46.648L46.766 49H56C56.2652 49 56.5196 48.8946 56.7071 48.7071C56.8946 48.5196 57 48.2652 57 48V44C57 43.7348 56.8946 43.4804 56.7071 43.2929C56.5196 43.1054 56.2652 43 56 43H47.119L47.943 29H49C49.2652 29 49.5196 28.8946 49.7071 28.7071C49.8946 28.5196 50 28.2652 50 28V24C50 23.7348 49.8946 23.4804 49.7071 23.2929C49.5196 23.1054 49.2652 23 49 23H42.963C42.7923 20.5402 41.9822 18.1676 40.613 16.117L39 13.7V10.68L40.537 10.065C41.3195 12.2722 42.9281 14.0894 45.024 15.134L48.553 16.9C48.757 17.0018 48.9896 17.0309 49.2123 16.9824C49.435 16.9339 49.6345 16.8107 49.7777 16.6334C49.9209 16.456 49.9992 16.235 49.9996 16.0071C50 15.7791 49.9225 15.5579 49.78 15.38L48.904 14.28C47.4147 12.4176 46.4575 10.1866 46.134 7.824L48.371 6.929C48.5567 6.8548 48.7159 6.72662 48.8281 6.56102C48.9402 6.39541 49.0001 6.19999 49 6V2C49 1.73478 48.8946 1.48043 48.7071 1.29289C48.5196 1.10536 48.2652 1 48 1H27C26.7348 1 26.4804 1.10536 26.2929 1.29289C26.1054 1.48043 26 1.73478 26 2V9C26.0001 9.22306 26.0747 9.4397 26.212 9.61546C26.3494 9.79122 26.5416 9.91602 26.758 9.97L30 10.78V13.38C28.4988 14.1102 27.2337 15.2485 26.3494 16.6644C25.4651 18.0803 24.9975 19.7166 25 21.386V23H19.618L12.583 8.931C12.1775 8.11781 11.4691 7.4962 10.61 7.19982C9.75104 6.90343 8.81003 6.95594 7.98933 7.34605C7.16863 7.73617 6.53377 8.43273 6.22123 9.28599C5.9087 10.1393 5.94344 11.0811 6.318 11.909L11.437 23H5C4.73478 23 4.48043 23.1054 4.29289 23.2929C4.10536 23.4804 4 23.7348 4 24V28C4 28.2652 4.10536 28.5196 4.29289 28.7071C4.48043 28.8946 4.73478 29 5 29H6.057L7.767 58.066C7.84697 59.4001 8.43295 60.6535 9.40539 61.5704C10.3778 62.4873 11.6635 62.9986 13 63H59C59.2652 63 59.5196 62.8946 59.7071 62.7071C59.8946 62.5196 60 62.2652 60 62V56C59.9984 54.6744 59.4711 53.4036 58.5338 52.4662C57.5964 51.5289 56.3256 51.0016 55 51V51Z"/>
  </svg>
)

export const StandardCleaningIcon = ({ className }) => (
  <svg className="h-12 w-12 mr-4 mt-2 dark:fill-white fill-malibu-500 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M67,22.35a8.72,8.72,0,0,1,6,2.48,8.49,8.49,0,0,1,2.61,6,1.8,1.8,0,0,0,1.61,1.67h0A1.5,1.5,0,0,0,78.73,31a8.34,8.34,0,0,1,8-8.59H87a1.5,1.5,0,0,0,0-3h-.1A7.88,7.88,0,0,1,81.24,17a8.6,8.6,0,0,1-2.43-5.45c0-.12,0-.24,0-.37a1.48,1.48,0,0,0-.07-.43A1.5,1.5,0,0,0,77.28,9.5h0A1.5,1.5,0,0,0,75.78,11c0,.18,0,.36,0,.55a8.42,8.42,0,0,1-8.58,7.78H67a1.5,1.5,0,0,0-1.48,1.52A1.52,1.52,0,0,0,67,22.35ZM77.31,16.7a11.5,11.5,0,0,0,1.79,2.39,11.21,11.21,0,0,0,2.3,1.79,11.4,11.4,0,0,0-4.27,4.45,11.94,11.94,0,0,0-2-2.64A11.68,11.68,0,0,0,72.8,21,11.49,11.49,0,0,0,77.31,16.7Z"/>
    <path d="M53.41,66A17,17,0,1,1,71,49.59a1.5,1.5,0,0,0,1.45,1.55A1.48,1.48,0,0,0,74,49.7,20,20,0,1,0,53.31,69h.05a1.5,1.5,0,0,0,.05-3Z"/>
    <path d="M24,31A10,10,0,1,0,14,21,10,10,0,0,0,24,31Zm0-17a7,7,0,1,1-7,7A7,7,0,0,1,24,14Z"/>
    <path d="M75.08,55a16.5,16.5,0,0,0-1.15,33h.58a16.5,16.5,0,0,0,.57-33Zm8.63,26.36A13.5,13.5,0,1,1,74.49,58H75a13.5,13.5,0,0,1,8.74,23.37Z"/>
    <path d="M18,46A13,13,0,1,0,31,59,13,13,0,0,0,18,46Zm0,23A10,10,0,1,1,28,59,10,10,0,0,1,18,69Z"/>
    <path d="M46.5,82.42h-.08a5.54,5.54,0,0,1-3.89-1.61,6.06,6.06,0,0,1-1.79-3.91c0-.09,0-.18,0-.27A1.7,1.7,0,0,0,39.21,75h0a1.5,1.5,0,0,0-1.48,1.51c0,.14,0,.27,0,.4-.12,3.34-3.19,5.44-6.06,5.47h-.19A1.5,1.5,0,0,0,30,83.9a1.49,1.49,0,0,0,1.52,1.48,5.92,5.92,0,0,1,4.22,1.76,6,6,0,0,1,1.85,4.23A1.72,1.72,0,0,0,39.17,93h0a1.5,1.5,0,0,0,1.48-1.51,5.88,5.88,0,0,1,5.66-6.06h.19a1.5,1.5,0,0,0,0-3Zm-7.41,4.09A9.4,9.4,0,0,0,37.84,85a9.29,9.29,0,0,0-1.26-1,9,9,0,0,0,2.65-2.48A9,9,0,0,0,40.43,83a8.83,8.83,0,0,0,1.17,1A8.93,8.93,0,0,0,39.09,86.52Z"/>
    <path d="M54.55,36c-6.69-.24-12.3,5.35-12.55,12.44A1.5,1.5,0,0,0,43.45,50h.05A1.5,1.5,0,0,0,45,48.55c.19-5.44,4.43-9.67,9.45-9.55A1.48,1.48,0,0,0,56,37.56,1.5,1.5,0,0,0,54.55,36Z"/>
    <path d="M74.05,60.5a9.51,9.51,0,0,0-9.55,9.44,1.5,1.5,0,0,0,1.45,1.55H66a1.5,1.5,0,0,0,1.5-1.45,6.55,6.55,0,0,1,6.45-6.55,1.47,1.47,0,0,0,1.55-1.45A1.5,1.5,0,0,0,74.05,60.5Z"/>
    <path d="M16.5,51.5c-3.58,0-6.5,3.36-6.5,7.5a1.5,1.5,0,0,0,3,0c0-2.48,1.57-4.5,3.5-4.5a1.5,1.5,0,0,0,0-3Z"/>
  </svg>
)

export const MoveOutsIcon = ({ className }) => (
  <svg className="h-12 w-12 mr-4 mt-2 dark:fill-white fill-malibu-500 flex-shrink-0" version="1.1" id="Line-Expand" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <path d="M61.667,28.255L47,15.103V13c0-1.654-1.346-3-3-3c-0.727,0-1.386,0.27-1.906,0.702l-9.427-8.447
	c-0.38-0.341-0.955-0.341-1.335,0l-29,26c-0.309,0.277-0.415,0.715-0.267,1.102C2.214,29.745,2.585,30,3,30h2v31
	c0,0.552,0.448,1,1,1h52c0.552,0,1-0.448,1-1V30h2c0.415,0,0.786-0.255,0.934-0.643C62.082,28.97,61.976,28.532,61.667,28.255z
	 M58.387,28H58H47V17.79L58.387,28z M50,50H38v-2.135C38,46.285,39.285,45,40.865,45h6.271C48.715,45,50,46.285,50,47.865V50z
	 M43,13c0-0.551,0.449-1,1-1s1,0.449,1,1v30h-2V13z M32,4.343l9.055,8.114C41.022,12.633,41,12.814,41,13v15H6H5.613L32,4.343z
	 M7,30h34v13h-0.135C38.182,43,36,45.182,36,47.865v2.942L32.323,60H25V41c0-0.552-0.448-1-1-1H12c-0.552,0-1,0.448-1,1v19H7V30z
	 M13,60V42h10v18H13z M49.82,60l-0.839-4.196l-1.961,0.393L47.78,60H45v-4h-2v4h-2.78l0.761-3.804l-1.961-0.393L38.18,60h-3.703
	l3.2-8h12.646l3.2,8H49.82z M57,60h-1.323L52,50.807v-2.942C52,45.182,49.818,43,47.135,43H47V30h10V60z"/>
                        <path d="M27,25h10c0.552,0,1-0.448,1-1v-6c0-3.309-2.691-6-6-6s-6,2.691-6,6v6C26,24.552,26.448,25,27,25z M28,20h3v3h-3V20z M33,23
	v-3h3v3H33z M36,18h-3v-3.858C34.72,14.589,36,16.142,36,18z M31,14.142V18h-3C28,16.142,29.28,14.589,31,14.142z"/>
                        <rect x="15" y="50" width="2" height="2"/>
                        <path d="M32,44h2c0-2.757,2.243-5,5-5v-2c-2.757,0-5-2.243-5-5h-2c0,2.757-2.243,5-5,5v2C29.757,39,32,41.243,32,44z M33,35.587
	c0.594,0.99,1.423,1.819,2.413,2.413c-0.99,0.594-1.819,1.423-2.413,2.413c-0.594-0.99-1.423-1.819-2.413-2.413
	C31.577,37.406,32.406,36.577,33,35.587z"/>
        </svg>
)


export const SoapIcon = ({ className }) => (
<svg className="h-12 w-12 mr-4 mt-2 dark:fill-white fill-malibu-500 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M208 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM320 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128zM416 32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm0 160c0 27.6-11.7 52.5-30.4 70.1C422.1 275.7 448 310.8 448 352c0 53-43 96-96 96l-192 0c-53 0-96-43-96-96s43-96 96-96l88.4 0c-15.2-17-24.4-39.4-24.4-64L96 192c-53 0-96 43-96 96L0 416c0 53 43 96 96 96l320 0c53 0 96-43 96-96l0-128c0-53-43-96-96-96zM160 288c-35.3 0-64 28.7-64 64s28.7 64 64 64l192 0c35.3 0 64-28.7 64-64s-28.7-64-64-64l-32 0-160 0z"/></svg>
)

export const BroomIcon = ({ className }) => (
<svg className="h-12 w-12 mr-4 mt-2 dark:fill-white fill-malibu-500 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M566.6 54.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192-34.7-34.7c-4.2-4.2-10-6.6-16-6.6c-12.5 0-22.6 10.1-22.6 22.6l0 29.1L364.3 320l29.1 0c12.5 0 22.6-10.1 22.6-22.6c0-6-2.4-11.8-6.6-16l-34.7-34.7 192-192zM341.1 353.4L222.6 234.9c-42.7-3.7-85.2 11.7-115.8 42.3l-8 8C76.5 307.5 64 337.7 64 369.2c0 6.8 7.1 11.2 13.2 8.2l51.1-25.5c5-2.5 9.5 4.1 5.4 7.9L7.3 473.4C2.7 477.6 0 483.6 0 489.9C0 502.1 9.9 512 22.1 512l173.3 0c38.8 0 75.9-15.4 103.4-42.8c30.6-30.6 45.9-73.1 42.3-115.8z"/></svg>
)