import React from 'react'
import { Helmet } from "react-helmet"
import Nav from "../../components/nav"
import Footer from "../../components/footer"
import BackgroundImage from "../../components/background-image"
import BGImage from "../../images/about-page-image.jpg"
import BGImageM from "../../images/forest-m.jpg"
import ServicesVertical from "../../components/services-vertical"
import CtaBarAlternate from "../../components/Cta-bar-alternate"

const ServicesPage = () => {
  return (
    <main className="flex flex-col min-h-screen">
      <Helmet>
        <title>Rancho Bernardo Maids - Our Services</title>
        <meta name="description" content="Explore our range of cleaning services including deep cleaning, standard cleaning, and move-out cleaning." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Nav />
      <BackgroundImage image={BGImage} mobileImage={BGImage} alt={"forest background"} height={"h-96"} backgroundHeight={"h-96"} hero={true}>
        <div className="flex items-center justify-center h-full bg-black bg-opacity-50 my-12 rounded-xl">
          <h1 className="text-4xl md:text-6xl lg:text-8xl text-center text-white font-bold p-6">Our Services</h1>
        </div>
      </BackgroundImage>
      <div className="bg-white py-12">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <p className="text-xl text-gray-700 leading-relaxed">
              At Rancho Bernardo Maids, we offer a variety of cleaning services to meet your needs. 
              Whether you're looking for a deep clean, regular maintenance, or move-out cleaning, we've got you covered.
            </p>
          </div>
        </div>
      </div>
      <ServicesVertical />
      <CtaBarAlternate />
      <Footer />
    </main>
  )
}

export default ServicesPage