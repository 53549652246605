import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { DeepCleaningIcon, StandardCleaningIcon, MoveOutsIcon, SoapIcon, BroomIcon } from '../components/ServiceIcons'

const iconMap = {
  'deep-cleaning': DeepCleaningIcon,
  'standard-cleaning': StandardCleaningIcon,
  'move-outs': MoveOutsIcon,
  'broom-icon': BroomIcon,
  'soap-icon': SoapIcon,
}

// Updated parseDetails function
export function parseDetails(details, maxLength = 150, expanded = false) {
  const formattedDetails = details.replace(/\n/g, '<br />');
  if (!expanded && formattedDetails.length > maxLength) {
    return formattedDetails.substring(0, maxLength) + '...';
  }
  return formattedDetails;
}

const ServiceItem = ({ title, description, details, icon, slug }) => {
  const [expanded, setExpanded] = useState(false)
  const Icon = iconMap[icon] || (() => null)

  return (
    <div className="flex flex-col md:flex-row items-start bg-white shadow-lg rounded-lg overflow-hidden mb-8 transition-all duration-300 hover:shadow-xl">
      <div className="p-6 flex items-center justify-center">
        <Icon className="w-24 h-24 text-malibu-500" />
      </div>
      <div className="p-6 flex-grow">
        <h2 className="text-2xl font-bold text-gray-800 mb-2">{title}</h2>
        <p className="text-gray-600 mb-4 leading-relaxed">{description}</p>
        <h3 className="text-lg font-semibold text-gray-700 mb-2">What's Included:</h3>
        <div 
          className="text-gray-600 mb-4 leading-relaxed"
          dangerouslySetInnerHTML={{ __html: parseDetails(details, 150, expanded) }}
        />
        {details.length > 150 && (
          <></>
          // <button
          //   onClick={() => setExpanded(!expanded)}
          //   className="text-malibu-500 font-semibold mb-4 focus:outline-none"
          // >
          //   {expanded ? 'Read Less' : 'Read More'}
          // </button>
        )}
        <Link 
          to={`/services/${slug}`} 
          className="inline-block bg-malibu-500 text-white font-bold py-2 px-4 rounded-full hover:bg-malibu-600 transition duration-300 ease-in-out"
        >
          Learn More
        </Link>
      </div>
    </div>
  )
}

const ServicesVertical = () => {
  const data = useStaticQuery(graphql`
    query {
      allServiceItem {
        nodes {
          id
          title
          slug
          description
          details
          icon
        }
      }
    }
  `)

  const serviceData = data.allServiceItem.nodes

  return (
    <section className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">Our Cleaning Services</h2>
        <div className="max-w-4xl mx-auto">
          {serviceData.map((service) => (
            <ServiceItem key={service.id} {...service} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default ServicesVertical